import { Fragment, useState } from 'react';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableFooter } from '@mui/material';
import Button from '@mui/material/Button';
import colors from "assets/theme/base/colors";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { Check, Block, DeleteForever, MarkChatRead } from '@mui/icons-material';
import { API_URL } from 'constants/api';
import authHeader from 'components/Authentication/DataService';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { Flag, EmojiFlags, KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';

function Row(props) {
    const handleOpen = (e) => {e.preventDefault(); setOpen(true)};
    const handleClose = () => setOpen(false);
    const { row } = props;
    const { type } = props;
    const [open, setOpen] = useState(false);
    const [notify, setNotify] = useState();
    const [notifyText, setNotifyText] = useState();
    const [failed, setFailed] = useState();
    const [failedText, setFailedText] = useState();
    function printJSON(data) {
        return (<div><pre>{JSON.stringify(data, null, 2)}</pre></div>)
    }
     

    
    function ackAlert(e) {
        if ( e.acknowledged_by ) {
            console.log("Already ack'd by " + e.acknowledged_by["username"])
        } else {
            fetch(API_URL + 'alerts/acknowledge/'+e.id,  {headers: authHeader() }).then(res => res.json())
                .then( (res) => {
                    const icn = document.getElementById(e.id).style.color="#218d83"
                    console.log(icn)

                    setNotify(true)
                    setNotifyText("Successfully acknowledged alert")
                })
                .catch( err => {
                    if ( err.status === 400 ) {
                        setFailed(true)
                        setFailedText("Alert ID not provided")
                    } else if ( err.status === 401 ) {
                        setFailed(true)
                        setFailedText("You do not have permission to acknowledge this alert")
                    }
                })
        }
    }
    function delAlert(e) {
        setFailed(false)
        setNotify(false)
        fetch(API_URL + 'alerts/delete/'+e.id,  {headers: authHeader() }).then(res => {
            if (!res.ok) {
                return Promise.reject(res);
            }
            return res.json();
        })
        .then( (res) =>{
            var table = document.getElementById("alert-table");
            var rowIndex = document.getElementById('row-'+e.id).rowIndex;
            table.deleteRow(rowIndex);
        })
        .catch( err => {
            if ( err.status === 424 ) {
                setFailed(true)
                setFailedText("Failed dependency: Please acknowledge the alert first.")
            }
            
        }) 

    }
        
    function formatClock(date) {
        const clock = new Date(date)
        return clock.toLocaleString()
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: 4,
        overflow: 'scroll',
        height: "90%",
        width: "65%",
    };
    return (
        <Fragment>  
            <TableRow
                key={row.id}
                id={`row-`+row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                <TableCell component="th" scope="row"><Typography variant="caption">{row.cloud_param}</Typography></TableCell>
                <TableCell align="left"><Typography variant="caption" color="darkgreen.main" component={Link} onClick={ handleOpen}>{row.item}</Typography></TableCell>
                <TableCell align="left"><Typography variant="caption">{row.trigger_def}</Typography></TableCell>
                <TableCell align="right"><Typography variant="caption">{formatClock(row.clock)}</Typography></TableCell>
                <TableCell align="center">
                    <Typography sx={{ cursor: "pointer" }} onClick={() => ackAlert(row)} variant="caption" color={row.acknowledged_by ? "primary.main" : "grey.500"} id={row.id}><MarkChatRead sx={{ fontSize: "small "}}/></Typography>
                </TableCell>
                <TableCell align="center">
                    <Typography sx={{ cursor: "pointer" }} onClick={() => delAlert(row)} variant='caption' color="grey.600" ><DeleteForever fontSize="small" /></Typography></TableCell>
            </TableRow>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                    timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Table size="small" aria-label="purchases">
                                <TableRow>
                                    <TableCell style={{ verticalAlign: 'top' }}  colSpan={2}><Typography variant="body2">Alert Details</Typography></TableCell>
                                    <TableCell><Typography variant="caption">{printJSON(row.payload)}</Typography></TableCell>
                                </TableRow>
                            
                            
                        </Table>
                    </Box>
                </Fade>
            </Modal>
        </Fragment>
    );
}

export default function AlertList({alerts}) {
  
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [open, setOpen] = useState(false);

   

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small" id="alert-table">
                <TableHead>
                    <TableRow><TableCell colSpan={5}>
                        <Typography variant="h6">Alerts</Typography></TableCell></TableRow>
                    <TableRow>
                        <TableCell>Tenant</TableCell>
                        <TableCell align="left">Item</TableCell>
                        <TableCell align="left">Trigger</TableCell>
                        <TableCell align="right">Time</TableCell>
                        <TableCell align="center">Ack'd</TableCell>
                        <TableCell align="right">Delete</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {alerts.map((row) => (
                    <Row key={row.id} row={row}  />
                        
                ))}
                </TableBody>
            </Table>
        </TableContainer>


    );
}
